/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { ReactNode } from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import Head from "./head"
import Body from "./body"
import Footer from "./footer"

import styled from "@emotion/styled"
import { Theme } from "./styles"

const Hero = styled.div({
  alignItems: "stretch",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  minHeight: "100vh",
})

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={data => (
      <>
        {Theme()}
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
          title={data.site.siteMetadata.title}
          meta={[
            { name: "title", content: data.site.siteMetadata.title },
            {
              name: "description",
              content: data.site.siteMetadata.description,
            },
          ]}
        >
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,300;1,100;1,300&display=swap" rel="stylesheet" />
        </Helmet>
        <Hero>
          <Head />
          <Body>{children}</Body>
          <Footer copyright="&copy; techmunchies, inc" />
        </Hero>
      </>
    )}
  />
)

export default Layout
