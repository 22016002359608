import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { colors, mq } from "./styles"

const FooterContainer = styled.footer({
  alignItems: "center",
  color: colors.textcolor,
  display: "flex",
  flexWrap: "wrap",
  fontSize: "1rem",
  justifyContent: "space-between",
  textAlign: "center",
  zIndex: 30,
  marginTop: '1rem',
})

const Copyright = styled.span({
  flexBasis: "100%",
  fontSize: "0.8rem",
  marginBottom: "-3rem",

  [mq[0]]: {
    marginBottom: "-20rem",
  },
})

const LoveLine = styled.span({
  marginRight: "0.15rem",
})

const LaptopIcon = styled.svg({
  display: "inline-flex",
  height: "0.75rem",
  marginRight: "0.15rem",
  overflow: "visible",
  verticalAlign: "-0.125rem",
  width: "1rem",
})

const Hidi = styled.span({
  display: "none",

  [mq[1]]: {
    display: "flex",
  },
})

const bottomPalmStyles = css({
  userSelect: "none",
  width: "100%",
})

interface FooterProps {
  copyright: string
}

const Footer = ({ copyright }: FooterProps) => (
  <FooterContainer>
    <Copyright>
      <LoveLine>
        <LaptopIcon
          aria-hidden="true" focusable="false" data-prefix="fas"
          data-icon="laptop-code" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
        >
          <path fill="currentColor"
                d="M255.03 261.65c6.25 6.25 16.38 6.25 22.63 0l11.31-11.31c6.25-6.25 6.25-16.38 0-22.63L253.25 192l35.71-35.72c6.25-6.25 6.25-16.38 0-22.63l-11.31-11.31c-6.25-6.25-16.38-6.25-22.63 0l-58.34 58.34c-6.25 6.25-6.25 16.38 0 22.63l58.35 58.34zm96.01-11.3l11.31 11.31c6.25 6.25 16.38 6.25 22.63 0l58.34-58.34c6.25-6.25 6.25-16.38 0-22.63l-58.34-58.34c-6.25-6.25-16.38-6.25-22.63 0l-11.31 11.31c-6.25 6.25-6.25 16.38 0 22.63L386.75 192l-35.71 35.72c-6.25 6.25-6.25 16.38 0 22.63zM624 416H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33.02-17.47-32.77-32H16c-8.8 0-16 7.2-16 16v16c0 35.2 28.8 64 64 64h512c35.2 0 64-28.8 64-64v-16c0-8.8-7.2-16-16-16zM576 48c0-26.4-21.6-48-48-48H112C85.6 0 64 21.6 64 48v336h512V48zm-64 272H128V64h384v256z"
          />
        </LaptopIcon>
        with ❤ by mario
      </LoveLine>
      {copyright}
    </Copyright>
    <StaticImage
      src="../../static/images/footer.png"
      css={bottomPalmStyles}
      alt="palm leaves"
      title="palm leaves"
      placeholder="blurred"
      layout="fullWidth"
      loading="eager"
      formats={['auto', 'webp', 'avif']}
    />
  </FooterContainer>
)

export default Footer
