import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { colors, mq } from "./styles"

const HeadContainer = styled.div({
  alignItems: "center",
  display: "flex",
  flexGrow: 0,
  flexShrink: 0,
  flexWrap: "wrap",
  justifyContent: "center",

  [mq[1]]: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
})

const NameContainer = styled.h1({
  display: "flex",
  flexBasis: "100%",
  fontSize: "2.5rem",
  fontWeight: 300,
  justifyContent: "center",
  letterSpacing: 1.5,
  lineHeight: 1.2,
  marginTop: "-1rem",

  [mq[0]]: {
    fontSize: "4rem",
    letterSpacing: 2,
    lineHeight: 1.5,
    margin: "-9rem 0",
  },
})

const DateContainer = styled.h2({
  display: "flex",
  flexBasis: "100%",
  fontSize: "2.4rem",
  fontWeight: 200,
  justifyContent: "center",
  letterSpacing: 1.5,
  lineHeight: 1.2,
  margin: "-2.4rem 0",
})

const Nav = styled.nav({
  backgroundColor: colors.bgcolor,
  display: "flex",
  flexBasis: "100%",
  justifyContent: "center",
  marginTop: "2.5rem",
  minHeight: "3.25rem",
  position: "sticky",
  top: 0,
  zIndex: 40,
})

const navContainerStates = {
  true: {
    boxShadow: "0 8px 16px rgba(10,10,10,.1)",
    display: "block",
    left: 0,
    position: "absolute",
    top: "2.2rem",
    width: "100%",
  },
}

const NavContainer = styled.div(
  {
    display: "flex",
    justifyContent: "center",
    transition: "max-height .2s ease-out",

    [mq[0]]: {
      flexGrow: 1,
      flexShrink: 0,
      alignItems: "stretch",
      display: "flex",
    },
  },

  props => navContainerStates[props.state]
)

const NavLinkContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",

  [mq[0]]: {
    flexWrap: "initial",
  },
})

// NavLink settings
const duration = ".2s"
const distance = "8px"
const easeOutBack = "cubic-bezier(0.175, 0.885, 0.320, 1.275)"

const NavLinkActiveStyle = {
  true: {
    ":after": {
      opacity: 1,
      transform: "translateY(0)",
      transition: "none",
      backgroundColor: colors.textcolor,
    },
  },
}

const NavLink = styled(Link)(
  {
    alignItems: "center",
    color: colors.textcolor,
    cursor: "pointer",
    display: "flex",
    flexBasis: "100%",
    fontSize: "1.5rem",
    fontWeight: 300,
    justifyContent: "center",
    letterSpacing: 1.2,
    lineHeight: 1,
    padding: "0.75rem 1.25rem",
    position: "relative",
    textDecoration: "none",

    ":not(:first-of-type):not(:last-of-type)": {
      padding: "0.75rem 0.625rem",
    },

    ":before, :after": {
      content: '""',
      position: "absolute",
      bottom: "2px",
      left: 0,
      right: 0,
      height: "2px",
      backgroundColor: "#80381a",
    },

    ":before": {
      opacity: 0,
      transform: `translateY(-${distance})`,
      transition: `transform 0s ${easeOutBack}, opacity 0s`,
    },

    ":after": {
      opacity: 0,
      transform: `translateY(${distance}/2)`,
      transition: `transform ${duration} ${easeOutBack}, opacity ${duration}`,
    },

    ":active, :hover, :focus": {
      color: "#80381a",

      ":before, :after": {
        opacity: 1,
        transform: "translateY(0)",
      },

      ":before": {
        transition: `transform ${duration} ${easeOutBack}, opacity ${duration}`,
      },

      ":after": {
        transition: `transform 0s ${duration} ${easeOutBack}, opacity 0s ${duration}`,
      },
    },

    [mq[0]]: {
      fontSize: "1.7rem",
      fontWeight: 200,
      justifyContent: "initial",
      lineHeight: 1.2,
      padding: "1rem 1.25rem",

      ":not(:first-of-type):not(:last-of-type)": {
        padding: "1rem 0.625rem",
      },
    },
  },

  props => NavLinkActiveStyle[props.isCurrent]
)

const NavLinkExternal = styled.a(
  {
    alignItems: "center",
    color: colors.textcolor,
    cursor: "pointer",
    display: "flex",
    flexBasis: "100%",
    fontSize: "1.7rem",
    fontWeight: 200,
    letterSpacing: 1.2,
    lineHeight: 1.2,
    padding: "1rem 1.25rem",
    position: "relative",
    textDecoration: "none",

    ":not(:first-of-type):not(:last-of-type)": {
      padding: "1rem 0.625rem",
    },

    ":before, :after": {
      content: '""',
      position: "absolute",
      bottom: "2px",
      left: 0,
      right: 0,
      height: "2px",
      backgroundColor: "white",
    },

    ":before": {
      opacity: 0,
      transform: `translateY(-${distance})`,
      transition: `transform 0s ${easeOutBack}, opacity 0s`,
    },

    ":after": {
      opacity: 0,
      transform: `translateY(${distance}/2)`,
      transition: `transform ${duration} ${easeOutBack}, opacity ${duration}`,
    },

    ":active, :hover, :focus": {
      color: "white",

      ":before, :after": {
        opacity: 1,
        transform: "translateY(0)",
      },

      ":before": {
        transition: `transform ${duration} ${easeOutBack}, opacity ${duration}`,
      },

      ":after": {
        transition: `transform 0s ${duration} ${easeOutBack}, opacity 0s ${duration}`,
      },
    },
  },

  props => NavLinkActiveStyle[props.isCurrent]
)

const bottomPalmStyles = css({
  userSelect: "none",
  width: "100%",
})

const Head = () => (
    <>
      <HeadContainer>
        <a id="home" src="#home" title="home" />
        <StaticImage
          src="../../static/images/header.png"
          css={bottomPalmStyles}
          alt="palm leaves"
          title="palm leaves"
          placeholder="blurred"
          layout="fullWidth"
          loading="eager"
          formats={['auto', 'webp', 'avif']}
        />
        <NameContainer>
          Courtney & Mario
        </NameContainer>
        <DateContainer>
          November 12, 2021
        </DateContainer>
      </HeadContainer>
      <Nav>
        <NavContainer>
          <NavLinkContainer>
            <NavLink to="#home">HOME</NavLink>
            <NavLink to="#cnm">C&M</NavLink>
            <NavLink to="#rsvp">RSVP</NavLink>
            <NavLink to="#venue">VENUE</NavLink>
            <NavLink to="#hotel">HOTEL</NavLink>
          </NavLinkContainer>
        </NavContainer>
      </Nav>
    </>
)

export default Head
